import {Typography, useTheme} from '@mui/material';
import ThemeEnum from "../../../configs/theme/enums/theme.enum";

const Icon = ({children, size, name, dark = false, light = false, ...props}) => {
    const theme = useTheme();
    const isDark = theme.palette.mode === ThemeEnum.DARK_MODE;
    
    return (
        <Typography sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }} {...props} height={size + 4} component="span" lineHeight={0} padding="2px">
            <img style={{width: `${size}px`}} src={`/img/icons/${name}${isDark || light ? '' : '-dark'}.svg`}/>
        </Typography>
    );
};

export const makeIcon = (name) => {
    return ({size = 24, ...props}) => (
        <Icon size={size} name={name} {...props} />
    );
};
