import { useContext } from 'react';
import { AppBar as MuiAppBar, Box, styled, Toolbar } from '@mui/material';
import UserMenu from './user-menu';
import useAuthorized from '../../../hooks/use-authorized.hook';
import { SidebarContext } from '../sidebar/context/sidebar.context';
import ThemeSwitcher from "./theme-switcher";
import LanguageSwitcher from "./language-switcher";

const drawerWidth = 280;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: theme.palette.background.secondary,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: '0 1px 3px 0 #a6afc357',
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = () => {
  const isAuthorised = useAuthorized();
  const sidebar = useContext(SidebarContext);
  
  return (
    <AppBar component="nav" open={sidebar.opened}>
      <Toolbar sx={{ justifyContent: 'end' }}>
        <Box sx={{ display: 'flex' }}>
          <LanguageSwitcher />
          <ThemeSwitcher />
          {isAuthorised && <UserMenu/>}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
