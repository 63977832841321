import {
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
} from './action-types';

export const signIn = (requestData, onSuccess, onError) => ({
  type: SIGN_IN_REQUEST,
  payload: requestData,
  meta: {
    onSuccess,
    onError,
  },
});

export const signInSuccess = (responseData) => ({
  type: SIGN_IN_SUCCESS,
  payload: responseData,
});

export const signInError = (error) => ({
  type: SIGN_IN_FAILURE,
  payload: error,
});

export const logout = () => ({
  type: LOGOUT_REQUEST,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutError = (error) => ({
  type: LOGOUT_FAILURE,
  payload: error,
});
