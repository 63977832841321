import { all, takeEvery } from 'redux-saga/effects';

import { LOGOUT_REQUEST, SIGN_IN_REQUEST } from '../actions/auth';

import { logout, signIn } from './auth';

function* rootSaga() {
  yield all([
    takeEvery(SIGN_IN_REQUEST, signIn),
    takeEvery(LOGOUT_REQUEST, logout),
  ]);
}

export default rootSaga;
