import { useEffect, useState } from 'react';
import { AuthStorage } from '../services/storage/auth.storage';


const useAuthorized = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const user = AuthStorage.getUser();
    setIsAuthorized(!(user == null));
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return null;
  }

  return isAuthorized;
};

export default useAuthorized;
