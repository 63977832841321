import { configureStore } from '@reduxjs/toolkit';

import createSagaMiddleware from 'redux-saga';

import reducer from './rootReducer';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ['meta'],
      },
    }).prepend(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
