const en = {
    translation: {
        "yes": "YES",
        "no": "NO",
        "loading": "Loading",
        "email": "Email",
        "password": "Password",
        "select-all": "Select all",
        "clear": "Clear",
        "confirm": "Confirm",
        "cancel": "Cancel",
        "save": "Save",
        "add": "Add",
        "apply": "Apply",
        "reset-all": "Reset All",

        "theme.set-dark": "Set dark mode",
        "theme.set-light": "Set light mode",

        "language.tooltip": "Set dark mode",

        "auth.login.button": "Sign In",
        "auth.logout.button": "Logout",
        "auth.login.error": "Can't login. Please try later",
        "auth.validation.email.invalid": "This email is not valid!",
        "auth.validation.email.required": "Email is required!",
        "auth.validation.password.required": "Password is required!",
        "auth.login.title": "Welcome back",
        "auth.login.subtitle": "Enter your account details",

        "profile.edit.button": "Edit Profile",
        "profile.title": "Profile",

        "uploading-files.title": "Uploading files",
        "uploading-files.upload.error": "An error occurred while uploading the file!",
        "uploading-files.dropzone.text.1": "Drag & Drop or",
        "uploading-files.dropzone.text.2": "Choose file",
        "uploading-files.dropzone.text.3": "to upload",
        "uploading-files.dropzone.text.active": "Drop here",

        "parsed-files.title": "Parsed files",
        "parsed-files.proceed": "Proceed",
        "parsed-files.table.title": "Table Name",
        "parsed-files.table.subtitle": "A descriptive body text comes here",
        "parsed-files.send-email": "Send Email",
        "parsed-files.send-email.success": "Email sent successfully",
        "parsed-files.send-email.error": "An error occurred while sending an email!",
        "parsed-files.export": "Export",
        "parsed-files.export.success": "Data exported successfully",
        "parsed-files.export.error": "An error occurred while exporting the data!",
        "parsed-files.update.error": "An error occurred while editing the file data!",
        "parsed-files.get.error": "An error occurred while loading data!",
        "parsed-files.proceed.success": "Data proceed successfully",
        "parsed-files.proceed.error": "An error occurred while proceeding the data!",
        "parsed-files.create.title": "Add new record",
        "parsed-files.create.error": "An error occurred while creating the record!",

        "parsed-table.eer_codes": "eer codes",
        "parsed-table.nome_file": "file name",
        "parsed-table.numero_onu": "UN number",
        "parsed-table.classe": "class",
        "parsed-table.data_di_revisione": "revision date",
        "parsed-table.gruppo_di_imballaggio": "packing group",
        "parsed-table.h_punto_2": "H point 2",
        "parsed-table.h_punto_3": "H point 3",
        "parsed-table.nome_del_prodotto": "product name",
        "parsed-table.pericoloso_per_lambiente": "environmental hazard",
        "parsed-table.stato_fisico": "state of matter",
        "parsed-table.uso_appropriato": "appropriate use",
        "parsed-table.cas": "CAS",
        "parsed-table.composizione_punto_3": "composition point 3",
        "parsed-table.concentrazione": "concentration",

        "files-history.title": "Files history",
        "files-history.empty-table": "Files upload history is empty",

        "eer-codes.label": "Select EER Codes",
        "eer-codes.get.error": "An error occurred while loading EER codes!",
        "eer-codes.filter": "EER code filter",

        "column-manager.title": "Manage columns",

        "not-found.title": "Page Not Found",
        "not-found.subtitle": "The page you are looking for does not exist.",

        "sub-rows.title": "Add sub-row",
        "sub-rows.field-required": "This field is required",

        "table-preferences.title": "Table Preferences",
        "table-preferences.light-mode": "Light mode",
        "table-preferences.dark-mode": "Dark mode",
        "table-preferences.even-color": "Even rows color",
        "table-preferences.odd-color": "Odd rows color",
        "table-preferences.selected-color": "Selected rows color",
        "table-preferences.text-color": "Text color",

        "profile.first-name.required": "Text color",
        "profile.last-name.required": "Text color",
    }
};

export default en;
