import { useMemo, useState } from 'react';

const useSidebarToggle = () => {
  const [opened, setOpened] = useState(false);

  return useMemo(
    () => ({
      opened,
      toggle: (open) => {
        setOpened((prevState) => (open === undefined ? !prevState : open));
      },
    }),
    [opened],
  );
};

export default useSidebarToggle;
