import {Suspense, useContext, useMemo} from 'react';
import { CircularProgress, Container } from '@mui/material';
import {Navigate, Route, Routes} from 'react-router-dom';

import ErrorBoundary from '../components/error-boundary';
import routes from './routes';
import useAuthorized from '../hooks/use-authorized.hook';
import { SidebarContext } from '../components/layouts/sidebar/context/sidebar.context';
import RouteEnum from "./enums/route.enum";

const Router = () => {
  const isAuth = useAuthorized();
  const sidebar = useContext(SidebarContext);

    const mappedRoutes = useMemo(() => {
        return routes.map((route) => {
            if ((isAuth && !(route.authOnly || !route.publicOnly)) || (!isAuth && route.authOnly)) {
                return {
                    ...route,
                    Component: () => <Navigate to={route.redirectTo || RouteEnum.NOT_FOUND} />,
                };
            }
            
            return route;
        });
    }, [isAuth]);
    
  if (isAuth === null) {
    return null;
  }

  return (
    <ErrorBoundary>
      <Suspense fallback={<CircularProgress />}>
        <Container
          sx={{
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
            width: `calc(100% - ${sidebar.opened ? '180px' : '20px'})`,
            overflowX: 'hidden',
          }}
          maxWidth={false}
        >
          <Routes>
            {mappedRoutes.map(({ path, Component }, index) => (
              <Route key={index} path={path} Component={Component} />
            ))}
          </Routes>
        </Container>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Router;
