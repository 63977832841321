const it = {
    translation: {
        "yes": "SI",
        "no": "NO",
        "loading": "Caricamento",
        "email": "Email",
        "password": "Password",
        "select-all": "Seleziona tutto",
        "clear": "Cancella filtro",
        "confirm": "Conferma",
        "cancel": "Annulla",
        "save": "Salva",
        "add": "Aggiungi",
        "apply": "Applica",
        "reset-all": "Reimposta tutto",

        "theme.set-dark": "Imposta modalità scura",
        "theme.set-light": "Imposta modalità chiara",

        "language.tooltip": "Imposta modalità scura",

        "auth.login.button": "Accedi",
        "auth.logout.button": "Disconnetti",
        "auth.login.error": "Impossibile accedere. Riprova più tardi",
        "auth.validation.email.invalid": "Questa email non è valida!",
        "auth.validation.email.required": "Email obbligatoria!",
        "auth.validation.password.required": "Password obbligatoria!",
        "auth.login.title": "Bentornato",
        "auth.login.subtitle": "Inserisci i dettagli del tuo account",

        "profile.edit.button": "Modifica profilo",
        "profile.title": "Profilo",

        "uploading-files.title": "Caricamento file",
        "uploading-files.upload.error": "Si è verificato un errore durante il caricamento del file!",
        "uploading-files.dropzone.text.1": "Trascina e rilascia",
        "uploading-files.dropzone.text.2": "Scegli file",
        "uploading-files.dropzone.text.3": "da caricare",
        "uploading-files.dropzone.text.active": "Rilascia qui",

        "parsed-files.title": "File analizzati",
        "parsed-files.proceed": "Procedi",
        "parsed-files.table.title": "Nome tabella",
        "parsed-files.table.subtitle": "Qui va un testo descrittivo",
        "parsed-files.send-email": "Invia Email",
        "parsed-files.send-email.success": "Email inviata con successo",
        "parsed-files.send-email.error": "Si è verificato un errore durante l'invio dell'email!",
        "parsed-files.export": "Esporta",
        "parsed-files.export.success": "Dati esportati con successo",
        "parsed-files.export.error": "Si è verificato un errore durante l'esportazione dei dati!",
        "parsed-files.update.error": "Si è verificato un errore durante la modifica dei dati del file!",
        "parsed-files.get.error": "Si è verificato un errore durante il caricamento dei dati!",
        "parsed-files.proceed.success": "Dati elaborati con successo",
        "parsed-files.proceed.error": "Si è verificato un errore durante l'elaborazione dei dati!",
        "parsed-files.create.title": "Aggiungi nuovo record",
        "parsed-files.create.error": "Si è verificato un errore durante la creazione del record!",

        "parsed-table.eer_codes": "codici eer",
        "parsed-table.nome_file": "nome file",
        "parsed-table.numero_onu": "numero onu",
        "parsed-table.classe": "classe",
        "parsed-table.data_di_revisione": "data di revisione",
        "parsed-table.gruppo_di_imballaggio": "gruppo di imballaggio",
        "parsed-table.h_punto_2": "h punto 2",
        "parsed-table.h_punto_3": "h punto 3",
        "parsed-table.nome_del_prodotto": "nome del prodotto",
        "parsed-table.pericoloso_per_lambiente": "pericoloso per l'ambiente",
        "parsed-table.stato_fisico": "stato fisico",
        "parsed-table.uso_appropriato": "uso appropriato",
        "parsed-table.cas": "cas",
        "parsed-table.composizione_punto_3": "composizione punto 3",
        "parsed-table.concentrazione": "concentrazione",

        "files-history.title": "Cronologia file",
        "files-history.empty-table": "La cronologia del caricamento file è vuota",

        "eer-codes.label": "Seleziona codici EER",
        "eer-codes.get.error": "Si è verificato un errore durante il caricamento dei codici EER!",
        "eer-codes.filter": "Filtro codici EER",

        "column-manager.title": "Gestisci colonne",

        "not-found.title": "Pagina non trovata",
        "not-found.subtitle": "La pagina che stai cercando non esiste.",

        "sub-rows.title": "Aggiungi sotto-riga",
        "sub-rows.field-required": "Questo campo è obbligatorio",

        "table-preferences.title": "Preferenze Tabella",
        "table-preferences.light-mode": "Modalità chiara",
        "table-preferences.dark-mode": "Modalità scura",
        "table-preferences.even-color": "Colore delle righe pari",
        "table-preferences.odd-color": "Colore delle righe dispari",
        "table-preferences.selected-color": "Colore delle righe selezionate",
        "table-preferences.text-color": "Colore del testo",
    }
};

export default it;
