export const AuthState = {
  authData: null,
  loading: false,
  error: null,
};

export const UIState = {
  successSnackbar: '',
  errorSnackbar: '',
  infoSnackbar: '',
};
