import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

const StyledLink = styled(RouterLink)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
}));

const Link = ({ to, children }) => {
  const theme = useTheme();

  return (
    <StyledLink theme={theme} to={to}>
      {children}
    </StyledLink>
  );
};

export default Link;

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
};
