import { AuthStorage } from '../storage/auth.storage';
import ResponseCodesEnum from './enums/response-codes.enum';
import axios from 'axios';

const refreshAccessToken = async (lastRequest) => {
  const refreshToken = AuthStorage.getRefreshToken();
  if (!refreshToken) return;

  try {
    const axiosInstance = axios.create({ baseURL: '/api' });
    const { data } = await axiosInstance.post(
      '/user/token/refresh/',
      { refresh: refreshToken },
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          'ngrok-skip-browser-warning': true,
        },
      },
    );
    if (data.access) {
      AuthStorage.removeAccessToken();
      AuthStorage.storeAccessToken(data.access);
      lastRequest.headers.Authorization = `Bearer ${data.access}`;
      return await axios.request(lastRequest);
    }
  } catch (e) {
    AuthStorage.removeAccessToken();
    AuthStorage.removeRefreshToken();
    AuthStorage.removeUser();
    window.location.reload();
  }
};

export function setupInterceptorsTo(axiosInstance) {
  const onRequest = async (config) => {
    config.headers.Authorization = `Bearer ${AuthStorage.getAccessToken()}`;
    config.headers['ngrok-skip-browser-warning'] = true;

    config.withCredentials = false;
    return config;
  };

  const onRequestError = async (error) => {
    return await Promise.reject(error);
  };

  const onResponse = (response) => response;

  const onResponseError = async (error) => {
    if (error.response?.status === ResponseCodesEnum.UNAUTHORIZED) {
      if (!AuthStorage.getAccessToken()) {
        return await Promise.reject(error);
      }
      return await refreshAccessToken(error.config);
    }

    return await Promise.reject(error);
  };

  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);

  return axiosInstance;
}
