export default class StorageService {
  store = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  get = (key) => {
    const data = localStorage.getItem(key);
    if (!data) return null;
    try {
      return JSON.parse(data);
    } catch (e) {
      return undefined;
    }
  };

  remove = (key) => {
    localStorage.removeItem(key);
  };
}
