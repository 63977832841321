import React, { useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import ReactDOM from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

import useColorMode from './configs/theme/hooks/use-color-mode.hook';
import store from './store';
import Router from './router';
import { ColorModeContext } from './configs/theme/context/color-mode.context';
import Sidebar from './components/layouts/sidebar';
import useSidebarToggle from './components/layouts/sidebar/hooks/use-sidebar-toggle.hook';
import { SidebarContext } from './components/layouts/sidebar/context/sidebar.context';
import SuccessSnackbar from './components/ui/snackbar';

import './index.css';
import Header from './components/layouts/header';
import useAuthorized from './hooks/use-authorized.hook';
import { AuthApi } from './services/api/auth';
import { signInSuccess } from './store/actions/auth';
import { AuthStorage } from './services/storage/auth.storage';

import './i18n';

dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  weekStart: 1,
});

const App = () => {
  const { colorMode, currentTheme } = useColorMode();
  const sidebarToggle = useSidebarToggle();
  const isAuth = useAuthorized();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      sidebarToggle.toggle(true);
      (async () => {
        const authData = await AuthApi.getAuthUser();
        dispatch(signInSuccess(authData));
        AuthStorage.storeUser(authData);
      })();
    }
  }, [isAuth]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <SidebarContext.Provider value={sidebarToggle}>
        <ThemeProvider theme={currentTheme}>
          <CssBaseline />
          <BrowserRouter>
            <SuccessSnackbar />
            <Box sx={{ display: 'flex' }}>
              <Header />
              <Sidebar />
              <Router />
            </Box>
          </BrowserRouter>
        </ThemeProvider>
      </SidebarContext.Provider>
    </ColorModeContext.Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

