const RouteEnum = {
  HOME: '/',
  NOT_FOUND: '/not-found',
  LOGIN: '/login',
  UPLOADING_FILES: '/uploading-files',
  PARSED_FILES: '/parsed-files',
  FILES_HISTORY: '/files-history',
  EDIT_PROFILE: '/edit-profile',
};

export default RouteEnum;
