import { useEffect, useState } from 'react';
import { AuthStorage } from '../services/storage/auth.storage';

const useAuthUser = () => {
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    setAuthUser(AuthStorage.getUser());
  }, []);

  return authUser;
};

export default useAuthUser;
