import ThemeEnum from "../../../../configs/theme/enums/theme.enum";
import {IconButton, Tooltip, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";

const LanguageSwitcher = () => {
        const theme = useTheme();
        const {t, i18n} = useTranslation();

    const toggleLanguage = () => {
        i18n.changeLanguage(i18n.language === 'en' ? 'it' : 'en');
    };

    const it = i18n.language === 'it';

    return (
        <IconButton onClick={toggleLanguage} color="inherit">
            <Tooltip title={t('language.tooltip')}>
                {it ? theme.palette.mode === ThemeEnum.LIGHT_MODE ? (
                        <svg width="57" height="34" viewBox="0 0 57 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="55" height="32" rx="16" fill="white"/>
                            <rect x="0.5" y="0.5" width="56" height="33" rx="16.5" stroke="#21262D" strokeOpacity="0.2"/>
                            <g clipPath="url(#clip0_238_3064)">
                                <path
                                    d="M17 31C24.732 31 31 24.732 31 17C31 9.26801 24.732 3 17 3C9.26801 3 3 9.26801 3 17C3 24.732 9.26801 31 17 31Z"
                                    fill="#F0F0F0"/>
                                <path
                                    d="M31 17C31 10.9805 27.2007 5.84889 21.8695 3.87079V30.1293C27.2007 28.1511 31 23.0195 31 17Z"
                                    fill="#D80027"/>
                                <path
                                    d="M3 17C3 23.0195 6.79925 28.1511 12.1305 30.1292V3.87079C6.79925 5.84889 3 10.9805 3 17Z"
                                    fill="#6DA544"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_238_3064">
                                    <rect width="28" height="28" fill="white" transform="translate(3 3)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    ) : (
                        <svg width="57" height="34" viewBox="0 0 57 34" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="55" height="32" rx="16" fill="white" fillOpacity="0.12"/>
                            <rect x="0.5" y="0.5" width="56" height="33" rx="16.5" stroke="white"
                                  strokeOpacity="0.2"/>
                            <g clipPath="url(#clip0_238_3757)">
                                <path
                                    d="M17 31C24.732 31 31 24.732 31 17C31 9.26801 24.732 3 17 3C9.26801 3 3 9.26801 3 17C3 24.732 9.26801 31 17 31Z"
                                    fill="#F0F0F0"/>
                                <path
                                    d="M31 17C31 10.9805 27.2007 5.84889 21.8695 3.87079V30.1293C27.2007 28.1511 31 23.0195 31 17Z"
                                    fill="#D80027"/>
                                <path
                                    d="M3 17C3 23.0195 6.79925 28.1511 12.1305 30.1292V3.87079C6.79925 5.84889 3 10.9805 3 17Z"
                                    fill="#6DA544"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_238_3757">
                                    <rect width="28" height="28" fill="white" transform="translate(3 3)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    ) :
                    theme.palette.mode === ThemeEnum.LIGHT_MODE ? (
                        <svg width="57" height="34" viewBox="0 0 57 34" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="55" height="32" rx="16" fill="white"/>
                            <rect x="0.5" y="0.5" width="56" height="33" rx="16.5" stroke="#21262D"
                                  strokeOpacity="0.2"/>
                            <g clipPath="url(#clip0_238_3067)">
                                <path
                                    d="M40 31C47.732 31 54 24.732 54 17C54 9.26801 47.732 3 40 3C32.268 3 26 9.26801 26 17C26 24.732 32.268 31 40 31Z"
                                    fill="#F0F0F0"/>
                                <path
                                    d="M28.894 8.47652C27.7942 9.90731 26.965 11.5564 26.4822 13.3479H33.7653L28.894 8.47652Z"
                                    fill="#0052B4"/>
                                <path
                                    d="M53.5176 13.3479C53.0347 11.5565 52.2054 9.90735 51.1058 8.47656L46.2345 13.3479H53.5176Z"
                                    fill="#0052B4"/>
                                <path
                                    d="M26.4822 20.6522C26.9651 22.4437 27.7943 24.0928 28.894 25.5235L33.7652 20.6522H26.4822Z"
                                    fill="#0052B4"/>
                                <path
                                    d="M48.5234 5.89411C47.0927 4.7944 45.4436 3.96518 43.6521 3.48228V10.7654L48.5234 5.89411Z"
                                    fill="#0052B4"/>
                                <path
                                    d="M31.4764 28.1059C32.9072 29.2056 34.5563 30.0348 36.3478 30.5177V23.2346L31.4764 28.1059Z"
                                    fill="#0052B4"/>
                                <path
                                    d="M36.3477 3.48228C34.5563 3.96518 32.9072 4.7944 31.4764 5.89406L36.3477 10.7653V3.48228Z"
                                    fill="#0052B4"/>
                                <path
                                    d="M43.6521 30.5177C45.4436 30.0348 47.0927 29.2056 48.5234 28.1059L43.6521 23.2346V30.5177Z"
                                    fill="#0052B4"/>
                                <path
                                    d="M46.2345 20.6522L51.1058 25.5236C52.2054 24.0928 53.0347 22.4437 53.5176 20.6522H46.2345Z"
                                    fill="#0052B4"/>
                                <path
                                    d="M53.8815 15.1739H41.8262H41.8261V3.11851C41.2283 3.04069 40.6189 3 40 3C39.381 3 38.7717 3.04069 38.1739 3.11851V15.1738V15.1739H26.1185C26.0407 15.7717 26 16.3811 26 17C26 17.619 26.0407 18.2283 26.1185 18.8261H38.1738H38.1739V30.8815C38.7717 30.9593 39.381 31 40 31C40.6189 31 41.2283 30.9594 41.8261 30.8815V18.8262V18.8261H53.8815C53.9593 18.2283 54 17.619 54 17C54 16.3811 53.9593 15.7717 53.8815 15.1739Z"
                                    fill="#D80027"/>
                                <path
                                    d="M43.6522 20.6522L49.8995 26.8995C50.1868 26.6123 50.4609 26.312 50.7224 26.0007L45.3739 20.6522H43.6522V20.6522Z"
                                    fill="#D80027"/>
                                <path
                                    d="M36.3478 20.6522H36.3477L30.1005 26.8995C30.3877 27.1868 30.688 27.4609 30.9993 27.7224L36.3478 22.3737V20.6522Z"
                                    fill="#D80027"/>
                                <path
                                    d="M36.3478 13.3479V13.3478L30.1005 7.10048C29.8132 7.3877 29.5391 7.68799 29.2776 7.99927L34.6262 13.3479H36.3478V13.3479Z"
                                    fill="#D80027"/>
                                <path
                                    d="M43.6522 13.3479L49.8996 7.10052C49.6123 6.81319 49.3121 6.53909 49.0008 6.27763L43.6522 11.6262V13.3479Z"
                                    fill="#D80027"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_238_3067">
                                    <rect width="28" height="28" fill="white" transform="translate(26 3)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    ) : (
                        <svg width="57" height="34" viewBox="0 0 57 34" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="55" height="32" rx="16" fill="white" fillOpacity="0.12"/>
                            <rect x="0.5" y="0.5" width="56" height="33" rx="16.5" stroke="white"
                                  strokeOpacity="0.2"/>
                            <g clipPath="url(#clip0_238_3762)">
                                <path
                                    d="M40 31C47.732 31 54 24.732 54 17C54 9.26801 47.732 3 40 3C32.268 3 26 9.26801 26 17C26 24.732 32.268 31 40 31Z"
                                    fill="#F0F0F0"/>
                                <path
                                    d="M28.894 8.47651C27.7942 9.9073 26.965 11.5564 26.4822 13.3479H33.7653L28.894 8.47651Z"
                                    fill="#0052B4"/>
                                <path
                                    d="M53.5176 13.3479C53.0347 11.5565 52.2054 9.90737 51.1058 8.47658L46.2345 13.3479H53.5176Z"
                                    fill="#0052B4"/>
                                <path
                                    d="M26.4822 20.6523C26.9651 22.4437 27.7943 24.0928 28.894 25.5235L33.7652 20.6523H26.4822Z"
                                    fill="#0052B4"/>
                                <path
                                    d="M48.5234 5.89412C47.0927 4.79441 45.4436 3.96518 43.6521 3.48229V10.7654L48.5234 5.89412Z"
                                    fill="#0052B4"/>
                                <path
                                    d="M31.4764 28.1059C32.9072 29.2056 34.5563 30.0348 36.3478 30.5177V23.2346L31.4764 28.1059Z"
                                    fill="#0052B4"/>
                                <path
                                    d="M36.3477 3.48229C34.5563 3.96518 32.9072 4.79441 31.4764 5.89407L36.3477 10.7654V3.48229Z"
                                    fill="#0052B4"/>
                                <path
                                    d="M43.6521 30.5177C45.4436 30.0348 47.0927 29.2056 48.5234 28.1059L43.6521 23.2346V30.5177Z"
                                    fill="#0052B4"/>
                                <path
                                    d="M46.2345 20.6523L51.1058 25.5236C52.2054 24.0929 53.0347 22.4437 53.5176 20.6523H46.2345Z"
                                    fill="#0052B4"/>
                                <path
                                    d="M53.8815 15.1739H41.8262H41.8261V3.11851C41.2283 3.04069 40.6189 3 40 3C39.381 3 38.7717 3.04069 38.1739 3.11851V15.1738V15.1739H26.1185C26.0407 15.7717 26 16.3811 26 17C26 17.619 26.0407 18.2283 26.1185 18.8261H38.1738H38.1739V30.8815C38.7717 30.9593 39.381 31 40 31C40.6189 31 41.2283 30.9594 41.8261 30.8815V18.8262V18.8261H53.8815C53.9593 18.2283 54 17.619 54 17C54 16.3811 53.9593 15.7717 53.8815 15.1739Z"
                                    fill="#D80027"/>
                                <path
                                    d="M43.6522 20.6523L49.8995 26.8995C50.1868 26.6123 50.4609 26.312 50.7224 26.0007L45.3739 20.6522H43.6522V20.6523Z"
                                    fill="#D80027"/>
                                <path
                                    d="M36.3478 20.6523H36.3477L30.1005 26.8995C30.3877 27.1868 30.688 27.4609 30.9993 27.7224L36.3478 22.3738V20.6523Z"
                                    fill="#D80027"/>
                                <path
                                    d="M36.3478 13.3479V13.3478L30.1005 7.10047C29.8132 7.38769 29.5391 7.68798 29.2776 7.99926L34.6262 13.3479H36.3478V13.3479Z"
                                    fill="#D80027"/>
                                <path
                                    d="M43.6522 13.3479L49.8996 7.10052C49.6123 6.81319 49.3121 6.53909 49.0008 6.27763L43.6522 11.6262V13.3479Z"
                                    fill="#D80027"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_238_3762">
                                    <rect width="28" height="28" fill="white" transform="translate(26 3)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    )
                }
            </Tooltip>
        </IconButton>
    );
};

export default LanguageSwitcher;