import ThemeEnum from "../../../../configs/theme/enums/theme.enum";
import {IconButton, Tooltip, useTheme} from "@mui/material";
import {useContext} from "react";
import {ColorModeContext} from "../../../../configs/theme/context/color-mode.context";
import {useTranslation} from "react-i18next";

const ThemeSwitcher = () => {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);
    const {t} = useTranslation();

    const toggleThemeMode = () => {
        colorMode.toggleColorMode();
    };

    return (
        <IconButton onClick={toggleThemeMode} color="inherit">
            {theme.palette.mode === ThemeEnum.LIGHT_MODE ? (
                <Tooltip title={t('theme.set-dark')}>
                    <svg width="57" height="34" viewBox="0 0 57 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="55" height="32" rx="16" fill="white"/>
                        <rect x="0.5" y="0.5" width="56" height="33" rx="16.5" stroke="#21262D" strokeOpacity="0.2"/>
                        <rect x="3" y="3" width="28" height="28" rx="14" fill="#1F76F1"/>
                        <path
                            d="M13 17C13 18.0609 13.4214 19.0783 14.1716 19.8284C14.9217 20.5786 15.9391 21 17 21C18.0609 21 19.0783 20.5786 19.8284 19.8284C20.5786 19.0783 21 18.0609 21 17C21 15.9391 20.5786 14.9217 19.8284 14.1716C19.0783 13.4214 18.0609 13 17 13C15.9391 13 14.9217 13.4214 14.1716 14.1716C13.4214 14.9217 13 15.9391 13 17Z"
                            fill="white"/>
                        <path
                            d="M8 17H9H8ZM17 8V9V8ZM25 17H26H25ZM17 25V26V25ZM10.6 10.6L11.3 11.3L10.6 10.6ZM23.4 10.6L22.7 11.3L23.4 10.6ZM22.7 22.7L23.4 23.4L22.7 22.7ZM11.3 22.7L10.6 23.4L11.3 22.7Z"
                            fill="white"/>
                        <path
                            d="M8 17H9M17 8V9M25 17H26M17 25V26M10.6 10.6L11.3 11.3M23.4 10.6L22.7 11.3M22.7 22.7L23.4 23.4M11.3 22.7L10.6 23.4M13 17C13 18.0609 13.4214 19.0783 14.1716 19.8284C14.9217 20.5786 15.9391 21 17 21C18.0609 21 19.0783 20.5786 19.8284 19.8284C20.5786 19.0783 21 18.0609 21 17C21 15.9391 20.5786 14.9217 19.8284 14.1716C19.0783 13.4214 18.0609 13 17 13C15.9391 13 14.9217 13.4214 14.1716 14.1716C13.4214 14.9217 13 15.9391 13 17Z"
                            stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

                </Tooltip>
            ) : (
                <Tooltip title={t('theme.set-light')}>
                    <svg width="57" height="34" viewBox="0 0 57 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="55" height="32" rx="16" fill="white" fillOpacity="0.12"/>
                        <rect x="0.5" y="0.5" width="56" height="33" rx="16.5" stroke="white" strokeOpacity="0.2"/>
                        <rect x="26" y="3" width="28" height="28" rx="14" fill="#1F76F1"/>
                        <path
                            d="M40 8C40.132 8 40.263 8 40.393 8C39.1084 9.19371 38.2826 10.7998 38.0593 12.5392C37.836 14.2786 38.2293 16.0412 39.1708 17.5207C40.1122 19.0002 41.5424 20.103 43.2126 20.6375C44.8829 21.1719 46.6876 21.1042 48.313 20.446C47.6878 21.9505 46.6658 23.257 45.3562 24.2263C44.0466 25.1955 42.4985 25.791 40.8769 25.9494C39.2554 26.1077 37.6213 25.823 36.1489 25.1254C34.6765 24.4279 33.4211 23.3437 32.5166 21.9886C31.6121 20.6335 31.0924 19.0583 31.0129 17.431C30.9334 15.8037 31.2972 14.1853 32.0654 12.7485C32.8336 11.3117 33.9774 10.1104 35.3748 9.27274C36.7722 8.43505 38.3708 7.9924 40 7.992V8Z"
                            fill="white"/>
                    </svg>

                </Tooltip>
            )}
        </IconButton>
    );
};

export default ThemeSwitcher;