import { call, put } from 'redux-saga/effects';
import { AuthApi } from '../../services/api/auth';
import { logoutError, logoutSuccess, signInError, signInSuccess } from '../actions/auth';
import { AuthStorage } from '../../services/storage/auth.storage';

export function* signIn({ payload, meta }) {
  try {
    const data = yield call(AuthApi.signIn, payload);
    yield call(AuthStorage.storeAccessToken, data.access);
    yield call(AuthStorage.storeRefreshToken, data.refresh);

    const authData = yield call(AuthApi.getAuthUser);
    yield put(signInSuccess({ authData }));
    yield call(AuthStorage.storeUser, authData);
    yield call(meta.onSuccess);
    yield call(() => {
      window.location.reload();
    });
  } catch (error) {
    console.log([error]);
    yield put(signInError(error));
    meta.onError(error?.response?.data || error?.data);
  }
}

export function* logout() {
  try {
    yield put(logoutSuccess());
    yield call(AuthStorage.removeUser);
    yield call(AuthStorage.removeAccessToken);
    yield call(AuthStorage.removeRefreshToken);
    yield call(() => {
      window.location.reload();
    });
  } catch (error) {
    console.log([error]);
    yield put(logoutError(error));
  }
}
