import axios from 'axios';
import { setupInterceptorsTo } from './interceptors';

export default class ApiService {
  constructor(pathPrefix) {
    this.pathPrefix = pathPrefix;
    this.instance = axios.create({ baseURL: '/api' });
    setupInterceptorsTo(this.instance);
  }

  get = async (url, query = {}, withoutPrefix, config = {}) => {
    return await this.instance.get(`/${!withoutPrefix ? this.pathPrefix : ''}${url}/`, { ...config, params: query });
  };

  post = async (url, data = {}, isJson = true, withoutPrefix, config = {}) => {
    return await this.instance.post(
      `/${!withoutPrefix ? this.pathPrefix : ''}${url}/`,
      isJson ? data : this.makeFormData(data),
      config,
    );
  };

  put = async (url, data = {}, withoutPrefix) => {
    return await this.instance.put(`/${!withoutPrefix ? this.pathPrefix : ''}${url}/`, data);
  };

  patch = async (url, data = {}, withoutPrefix, params) => {
    return await this.instance.patch(`/${!withoutPrefix ? this.pathPrefix : ''}${url}/`, data, {
      params,
    });
  };

  delete = async (url, query = {}) => {
    return await this.instance.delete(`/${this.pathPrefix}${url}/`, query);
  };

  makeFormData = (data) => {
    const formData = new FormData();
    const appendFormData = (dataToAppend, path = '') => {
      Object.entries(dataToAppend || {}).forEach(([name, value]) => {
        const newPath = path ? `${path}[${name}]` : name;
        if (name.includes('file') || name === 'image' || name === 'pdf') {
          formData.append(newPath, value);
        } else if (typeof value === 'object') {
          appendFormData(value, newPath);
        } else if (typeof value === 'boolean') {
          formData.append(newPath, value ? '1' : '0');
        } else {
          formData.append(newPath, value);
        }
      });
    };
    appendFormData(data);

    return formData;
  };
}
