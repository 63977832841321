import StorageService from './storage-service';

class AuthStorageService extends StorageService {
  storeUser = (user) => {
    this.store('user', user);
  };

  getUser = () => this.get('user');

  removeUser = () => {
    this.remove('user');
  };

  storeAccessToken = (token) => {
    this.store('accessToken', token);
  };

  storeRefreshToken = (token) => {
    this.store('refreshToken', token);
  };

  getAccessToken = () => this.get('accessToken');

  getRefreshToken = () => this.get('refreshToken');

  removeAccessToken = () => {
    this.remove('accessToken');
  };

  removeRefreshToken = () => {
    this.remove('refreshToken');
  };

  changeThemeMode = (mode) => {
    this.store('mode', mode);
  };

  getTaskId = () => this.get('taskId');

  removeTaskId = () => {
    this.remove('taskId');
  };

  changeTaskId = (taskId) => {
    this.store('taskId', taskId);
  };

  getThemeMode = () => this.get('mode');
}

export const AuthStorage = new AuthStorageService();
